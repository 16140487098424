<style>
  @import "/css/story.css";
</style>

<template>
    <div class="services">
        <Soin></Soin>
    </div>
</template>

<script>
import Soin from '@/components/Services/Soin.vue'

export default {
    name: 'Y-soin',

    components: {
        Soin
    }
}
</script>
