<template>
  <div id="wrapper" class="divided">

    <!-- One -->
    <section class="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
      <div class="content">
        <img src="/images/y_soin.png" class="service-name" alt="" />
        <p>
          Pour favoriser la santé et le bien-être de votre corps, je propose des techniques de toucher thérpeutique inspirées de la médecine traditionnelle chinoise. Ces précieux outils vous aideront à gérer les tensions et le stress, tout en stimulant les processus d'autoguérision de votre corps. Ces différents techniques offrent un soutien complémentaire à la nutrition, pour un soin global et équilibré.
        </p>

        <router-link class="button" to="/prices">
            Tarifs
        </router-link>
      </div>
      <div class="image">
        <img src="/images/soin/2.jpg" alt="" />
      </div>
    </section>

    <!-- Two -->
    <section class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
      <div class="content">

        <h2>Les différentes techniques manuelles</h2>
        <ul>
          <li>Auriculothérapie (méthode de réflexologie auriculaire)</li>
          <li>Pose de ventouses en plastique et en bambou</li>
          <li>Réflexologie plantaire et faciale</li>
          <li>Moxibustion</li>
        </ul>
      </div>
      <div class="image">
        <img src="/images/soin/1.jpg" alt="" />
      </div>
    </section>
  </div>
</template>
